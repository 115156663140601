

/* The side navigation menu */
.spotifyresults {
  height: 100%; /* 100% Full-height */
  max-width: 300px;
  margin: auto;
  position: fixed; /* Stay in place */
  z-index: 1; /* Stay on top */
  top: 0; /* Stay at the top */
  left: 0;
  background-color: rgb(87, 196, 196); /* Black*/
  padding-top: 60px; /* Place content 60px from the top */
}

/* Parent Container */
.content_img{
  position: relative;
  width: 100px;
  height: 150px;
  float: left;
  margin-right: 3px;
}

/* Child Text Container */
.content_img div{
  position: relative;
  bottom: 140;
  right: 0;
  background: black;
  color: white;
  margin-bottom: 5px;
  font-family: sans-serif;
  font-size:x-small;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: visibility 0s, opacity 0.5s linear; 
  transition: visibility 0s, opacity 0.5s linear;
}

/* Hover on Parent Container */
.content_img:hover{
  cursor: pointer;
}

.content_img:hover div{
  width: 100px;
  padding: 8px 15px;
  visibility: visible;
  opacity: 0.7; 
}


.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

#map { height: 100vh; }
